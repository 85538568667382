<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add container"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
    </table-custom>
  </div>
</template>

<script>
export default {
  props: {},
  name: "FermentationContainersTable",
  components: {},
  data: function() {
    return {
      dataset: {
        name: "fermentation-containers"
      },
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Container Number",
            "Capacity",
            "Amount Remaining",
            "Fullness %"
          ],
          filterable: [
            "ID",
            "Container Number",
            "Capacity",
            "Amount Remaining",
            "Fullness %"
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.post("fermentation-containers", payload);

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Fermentation container submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Fermentation container submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Fermentation container submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Fermentation container #${item.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`fermentation-container/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
