import { render, staticRenderFns } from "./FermentationContainersTable.vue?vue&type=template&id=a3bfb5c8&scoped=true"
import script from "./FermentationContainersTable.vue?vue&type=script&lang=js"
export * from "./FermentationContainersTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3bfb5c8",
  null
  
)

export default component.exports